// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/primitives/FormButtons.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/primitives/FormButtons.tsx");
  import.meta.hot.lastModified = "1732006320682.0298";
}
// REMIX HMR END

import { cn } from "~/utils/cn";
export function FormButtons({
  cancelButton,
  confirmButton,
  className
}) {
  return <div className={cn("flex w-full items-center justify-between border-t border-grid-bright pt-4", className)}>
      {cancelButton ? cancelButton : <div />} {confirmButton}
    </div>;
}
_c = FormButtons;
var _c;
$RefreshReg$(_c, "FormButtons");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;